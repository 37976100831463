@import url('../common/global_styles.css');

.contactContainer {
    width: 96%;
    align-items: center;
    background-color: var(--primary_color);
    margin: 2%;
}

.contactAnchor {
    text-decoration: none;
}

/* .contactUsHeader {
    color: var(--secondary_color);
    text-align: center;
} */


.fb-page {
    width: 100%;
    height: 100%;
}
