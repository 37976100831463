@import url('../common/global_styles.css');

.z_indexed_overlay_clickoff {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0;
    animation: blur .2s linear forwards;
}

@keyframes blur {
    to {
        backdrop-filter: blur(2.8px);
    }
}