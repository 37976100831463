@import url('common/global_styles.css');

.banner {
  width: 100%;
  height: var(--banner_height);
  /* background-image:url('/public/water_texture.jpg'); */
  /* background-image: url('/public/shower_stock.jpg'); */
  /* background-image:url('/public/water_drop.jpg'); */
  /* background-image:url('/public/drops.jpg'); */
  background-image:url('/public/next.jpg');
  background-repeat: round;
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  background-size: 100%;
  margin: auto;
}

.bannerLogo {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: flex;
}

@media (max-width: 550px), (hover: none) {
  .banner {
    height: var(--banner_height_mobile);
  }
}