@import url('../common/global_styles.css');

:root {
    --content-border-radius: .5rem;
    --content-animation-duration: .6s;
    --content-section-padding: 10%;
}

.contentSection {
    visibility: hidden;
    padding-top: var(--content-section-padding);
    padding-bottom: var(--content-section-padding);
    justify-content: center;
    text-align: center;
    margin: 3%;
    height: 50%;
    width: 65%;
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
    animation-name: none !important;
}


@media (max-width: 550px), (hover: none) {
    .contentSection {
        width: 100%;
    }
}