@import url('../common/global_styles.css');

:root {
    --galleryFrameSize: 300px;
    --galleryAnimationScale: .05;
}

.galleryContainer {
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    position: inherit;
    z-index: var(--gallery-container-z);
}

.galleryItem {
    min-height: var(--galleryFrameSize);
    min-width: var(--galleryFrameSize);
    overflow: hidden;
    object-fit: cover;
    object-fit: cover;
    overflow: hidden;
    height: var(--galleryFrameSize);
    width: var(--galleryFrameSize);
    margin: calc(var(--galleryFrameSize) * var(--galleryAnimationScale) / 2);
    border: 1rem;
    border-color: var(--secondary_color);
    border-radius: 10%;
}

/* .transformationContainer {
    margin: 0;
} */

@keyframes fadeOut {
    0% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
    }
}

.before {
    overflow: hidden;
    position: absolute;
    object-fit: cover;
    height: inherit;
    width: inherit;
    border-radius: inherit;
    animation: fadeOut 4.5s cubic-bezier(.66, -0.25, .46, 1.23) 0.5s infinite forwards;
}

.after {
    object-fit: cover;
    overflow: hidden;
    height: inherit;
    width: inherit;
}

.focusedTransformationContainer {
    margin: 0;
    padding: 0;
    min-height: 0% !important;
    min-width: 0% !important;
}

.slide {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    left: 0%;
    top: 0px;
    width: 200%;
    height: 100%;
}

.transformationDivider {
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10000;
    background-color: white;
    opacity: 75%;
    height: 100%;
    /* add mask and keep touch area large? yes */
    width: 5vw;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(255, 0, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))
}

.focusedBefore {
    margin: 0;
    padding: 0;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;

    /* These prevent the dragging action from accidentilly selecting/highlighting the image. */

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
    supported by Chrome and Opera */
}

.galleryItem:hover {
    animation: pop 150ms ease-in-out forwards;
}

/* .galleryHeader {
    color: var(--secondary_color);
    text-align: center;
} */

@keyframes pop {
    to {
        scale: calc(1 + var(--galleryAnimationScale));
    }
}

.z_indexed_overlay_clickoff {
    z-index: var(--overlay-clickoff-gallery-z);
}

.x {
    width: 4.24rem;
    height: 4.24rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: var(--gallery-overlay-x-button-z);
    top: 2%;
    left: calc(100% - 4.4rem - 2%);
    background-color: rgba(0, 0, 0, 0);
    border: 0;
}

.crossMember1 {
    background-color: var(--secondary_color);
    height: .5rem;
    width: 100%;
    rotate: 45deg;
    translate: 0 1.8rem;
}

.crossMember2 {
    background-color: var(--secondary_color);
    height: .5rem;
    width: 100%;
    rotate: -45deg;
    translate: 0 -1.8rem;
}

.focusedMedia {
    object-fit: cover;
    position: fixed;
    margin: 5%;
    min-height: 30%;
    min-width: 30%;
    max-height: 90%;
    max-width: 90%;
    z-index: var(--gallery-focused-media-z);
    border-radius: 1%;
    animation: overlayFade 0.15s linear;
}

.overlay {
    position: fixed;
    z-index: var(--gallery-overlay-z);
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: both;
    justify-content: center;
    background-color: rgba(50, 50, 50, .5);
}

@keyframes overlayFade {
    from {
        scale: 0;
    }

    to {
        scale: 1;
    }
}

@keyframes overlayFadeOut {
    from {
        scale: 1;
    }

    to {
        scale: 0;
    }
}