:root {
    /* #153e6a; */
    --aj_blue_r: 21;
    --aj_blue_g: 62;
    --aj_blue_b: 106;
    --aj_blue: rgba(var(--aj_blue_r), var(--aj_blue_g), var(--aj_blue_b), 1);
    /* #f75454 */
    --aj_red_r: 247;
    --aj_red_g: 84;
    --aj_red_b: 84;
    --aj_red: rgba(var(--aj_red_r), var(--aj_red_g), var(--aj_red_b), 1);

    --primary_color_r: 200;
    --primary_color_g: 200;
    --primary_color_b: 200;
    --primary_color: rgba(var(--primary_color_r), var(--primary_color_g), var(--primary_color_b), 100);
    --secondary_color_r: 42;
    --secondary_color_g: 124;
    --secondary_color_b: 212;
    --secondary_color: rgba(var(--secondary_color_r), var(--secondary_color_g), var(--secondary_color_b), 1);

    --ordinary_text_color: white;
    --heading_text_color: var(--aj_red);
    --link_text_color: var(--aj_red);
    --primary_background_color: var(--primary_color);
    --secondary_background_color: var(--aj_blue);
    --secondary_background_color_no_alpha: var(--aj_blue_r), var(--aj_blue_g), var(--aj_blue_b);
    --nav_background_color: rgba(255, 255, 255, 0.4);
    --nav_link_color: var(--aj_blue);

    --patty_size: .34rem;
    --patty1_rotate: -45deg;
    --patty2_rotate: 90deg;
    --patty3_rotate: 45deg;
    --ham_ani_time: 0.3s;
    --banner_height: 21rem;
    --banner_height_mobile: 10rem;

    /* z indices */
    --gallery-container-z: 1;
    --overlay-z: 2;
    --dropdown-z: 6;
    --overlay-clickoff-nav-z: 99;
    --nav-bar-z: 100;
    --hamburger-z: 150;
    --overlay-clickoff-gallery-z: 151;
    --gallery-overlay-z: 151;
    --gallery-focused-media-z: 175;
    --gallery-overlay-x-button-z: 200;
    /* --transformation-before-z: 8;
    --transformation-after-z: 9; */

}

.pageHeader {
    text-align: center;
    color: var(--heading_text_color);
    padding: 1%;
}

a {
    color: var(--link_text_color);
}

p,
ul,
ol,
h1,
body {
    color: var(--ordinary_text_color);
}

body {
    /* font-family: 'Cooper Black'; */
    font-size: x-large;
}