@import url('../common/global_styles.css');

/* .areaHeader {
    color: var(--secondary_color);
    text-align: center;
} */

.areas {
    /* var() */
    font-size: 2.5rem;
    list-style-position: inside;
}
