@import url('../common/global_styles.css');

:root {
    --content-border-radius: .5rem;
    --content-animation-duration: .6s;
}

.content {
    height: 100%;
    width: 100%;
}

@keyframes slideFromLeft {
    0% {
        translate: -100%;
    }

    100% {
        translate: 0;
    }
}

@keyframes slideFromRight {
    0% {
        translate: 100%;
    }

    100% {
        translate: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

.content> :nth-child(odd) {
    float: left;
    animation: slideFromLeft var(--content-animation-duration) cubic-bezier(0.075, 0.82, 0.165, 1), fadeIn var(--content-animation-duration) cubic-bezier(0.075, 0.82, 0.165, 1);
    margin-left: 0;
    border-top-right-radius: var(--content-border-radius);
    border-bottom-right-radius: var(--content-border-radius);
    background: linear-gradient(to right, rgba(var(--secondary_background_color_no_alpha), 1), rgba(var(--secondary_background_color_no_alpha), .9), rgba(var(--secondary_background_color_no_alpha), .8), rgba(0, 0, 0, 0));
}

.content> :nth-child(even) {
    float: right;
    animation: slideFromRight var(--content-animation-duration) cubic-bezier(0.075, 0.82, 0.165, 1), fadeIn var(--content-animation-duration) cubic-bezier(0.075, 0.82, 0.165, 1);
    margin-right: 0;
    border-top-left-radius: var(--content-border-radius);
    border-bottom-left-radius: var(--content-border-radius);
    background: linear-gradient(to left, rgba(var(--secondary_background_color_no_alpha), 1), rgba(var(--secondary_background_color_no_alpha), .9), rgba(var(--secondary_background_color_no_alpha), .8), rgba(0, 0, 0, 0));
}