@import url('../common/global_styles.css');

/* .homeHeader {
    text-align: center;
    color: var(--aj_red);
    padding: 2%;
} */

.homeContainer {
    display: flex;
}