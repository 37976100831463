@import url('../common/global_styles.css');

.hamburgerBox {
    display: none;
    height: fit-content;
    padding: 0;
    margin: 0;
}


.hamburgerPatty {
    background-color: var(--secondary_color);
    width: 100%;
    height: var(--patty_size);
}

.hamburger {
    margin: 0;
    scale: 1;
    background-color: transparent;
}

.pattyBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(var(--patty_size) * 10);
    height: calc(var(--patty_size) * 7);
    background-color: transparent;
    border: 0;
    scale: 0.6;
}

#patty1.active {
    animation: cross1 var(--ham_ani_time) forwards;
}

#patty1:not(.active) {
    animation: uncross1 var(--ham_ani_time) forwards;
}

#patty2.active {
    animation: cross2 var(--ham_ani_time) forwards;
}

#patty2:not(.active) {
    animation: uncross2 var(--ham_ani_time) forwards;
}

#patty3.active {
    animation: cross3 var(--ham_ani_time) forwards;
}

#patty3:not(.active) {
    animation: uncross3 var(--ham_ani_time) forwards;
}

@keyframes cross1 {
    from {
        rotate: 0deg;
        translate: 0px;
    }

    to {
        rotate: var(--patty1_rotate);
        translate: 0 1rem;
    }
}

@keyframes uncross1 {
    from {
        rotate: var(--patty1_rotate);
        translate: 0 1rem;
    }

    to {
        rotate: 0deg;
        translate: 0px;
    }
}

@keyframes cross2 {
    from {
        rotate: 0deg;
        opacity: 1;
    }

    to {
        rotate: var(--patty2_rotate);
        opacity: 0;
    }
}

@keyframes uncross2 {
    from {
        opacity: 0;
        rotate: var(--patty2_rotate);
    }

    to {
        opacity: 1;
        rotate: 0deg;
    }
}

@keyframes cross3 {
    from {
        rotate: 0deg;
        translate: 0px;
    }

    to {
        rotate: var(--patty3_rotate);
        translate: 0 -.88rem;
    }
}

@keyframes uncross3 {
    from {
        rotate: var(--patty3_rotate);
        translate: 0 -.88rem;
    }

    to {
        rotate: 0deg;
        translate: 0px;
    }
}

@media (max-width: 550px), (hover: none) {

    .hamburgerBox {
        display: flex;
        padding: 0;
        margin: 0;
        height: fit-content;
        position: sticky;
        top: 0;
        background-color: var(--primary_color);
        z-index: var(--hamburger-z);
    }

    .hamburgerBox.active {
        width: fit-content;
        position: fixed;
        top: 0;
        background-color: transparent;
    }
}