@import url('../common/global_styles.css');


.LoadingContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
}

.LoadingBar {
    animation: spin .4s infinite linear;
    padding: 4rem;
}

@keyframes spin {
    from {
        rotate: 0deg;
        color: var(--secondary_color);
    }
    to {
        rotate: 360deg;
        color: var(--primary_color);
    }
}
