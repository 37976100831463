@import url('common/global_styles.css');

html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, var(--primary_color) 30%, rgba(calc(var(--primary_color_r) * 1.8), calc(var(--primary_color_g) * 1.8), calc(var(--primary_color_b) * 1.8), 1) 100%);
  background-repeat: no-repeat;
  background-attachment: scroll;
  min-width: 100%;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@media (max-width: 550px), (hover: none) {
  body::-webkit-scrollbar {
    display: none;
  }

  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

}