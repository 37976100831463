@import url('../common/global_styles.css');

.dropdown {
    margin: 0;
    background-color: var(--primary_color);
    height: auto;
    width: 100%;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    z-index: var(--dropdown-z);
    display: none;
}

button {
    font-size: large;
    margin-top: .3rem;
    margin-bottom: .3rem;
    height: auto;
    border: 0;
    color: var(--secondary_color);
}