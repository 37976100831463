@import url('../common/global_styles.css');

.topNavBar {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.linkContainer {
  position: relative;
  min-width: fit-content;
}

.links,
.dropDownButtons {
  font-size: x-large;
  padding: 0%;
  font-weight: bold;
  background-color: transparent;
  color: var(--nav_link_color);
  text-decoration: none;
  /* todo this messes up when i tri to inherit percentages */
  display: block;
  flex-grow: 1;
  text-align: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  background-color: var(--nav_background_color);
  /* I think a certain browser needs this */
  -webkit-tap-highlight-color: rgba(10, 10, 10, .5);
}

.dropDownButtons {
  display: none;
}

@media (max-width: 550px), (hover: none) {

  .topNavBar {
    flex-direction: row;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
    width: 70%;
    height: 100vh;
    z-index: var(--nav-bar-z);
    padding-top: 10vh;
    background-color: var(--primary_color);
  }

  .linkContainer {
    margin-top: 3vh;
  }

  .topNavBar.active {
    display: block;
    animation-play-state: paused;
    animation: scoot .3s linear forwards;
  }

  .topNavBar:not(.active) {
    animation-play-state: paused;
    animation: scootBack .3s linear forwards;
  }

  .topNavBar:not(.active) a {
    display: none;
  }

  @keyframes scootBack {
    from {
      transform: translate(0);
    }

    to {
      transform: translate(-100%);
    }
  }

  @keyframes scoot {
    from {
      transform: translate(-100%);
    }

    to {
      transform: translate(0);
    }
  }

  .dropdown a {
    text-indent: 30%;
  }

  .links,
  .dropDownButtons {
    text-indent: 10%;
    text-align: left;
  }

  .dropDownLinks {
    display: none;
  }

  .dropDownButtons {
    display: block;
    width: 100%;
  }

  .dropDownButtons.active {
    background-color: rgba(10, 10, 10, .5);
  }

  .dropDownButtons.active~.dropdown {
    display: block;
    background-color: transparent;
    position: relative;
    overflow-y: hidden;
    animation: expand .18s linear forwards;
  }

  @keyframes expand {
    from {
      transform: translateX(-100%);
      opacity: 0%;
    }

    to {
      transform: translateX(0%);
      opacity: 100%;
    }
  }

}

.dropdown .links {
  font-size: large;
}

@media(hover: hover) {

  .links:hover,
  .dropDownButtons:hover {
    background-color: rgba(10, 10, 10, .5);
  }
}

@media(hover: hover) and (min-width: 550px) {

  .links:hover~.dropdown {
    display: block;
  }

  .dropdown:hover {
    display: block;
  }
}

.noAnimate {
  animation-duration: 0ms !important;
}

.z_indexed_overlay_clickoff {
  z-index: var(--overlay-clickoff-nav-z);
}