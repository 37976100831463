@import url('../common/global_styles.css');

/* .servicesHeader {
    color: var(--secondary_color);
    text-align: center;
} */

.services {
    font-size: 2.5rem;
    list-style-position: inside;
}

.serviceHeading {
    color: var(--aj_red);
}